import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import config from './config';

ReactGA.initialize(config.google.analyticsId);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

window.onload = () => {
  window.location.href = `${config.site}/i/${params.map_id ? params.map_id : ''}?${urlSearchParams}`;
}

const app = (
  <></>
);

ReactDOM.render(app, document.getElementById('root'));
