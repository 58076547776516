export default {
  google: {
    key: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_API_KEY || 'AIzaSyDYvCThcZPz6H2f8qcOuDoAfV7JS2mpCY4'
      : process.env.REACT_APP_API_KEY || 'AIzaSyDYvCThcZPz6H2f8qcOuDoAfV7JS2mpCY4',
    analyticsId: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_GA_ID || 'UA-156339668-2'
      : process.env.REACT_APP_GA_ID || 'UA-156339668-2',
  },
  api: {
    url: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_BACKEND_URL || 'https://api.steps.me/v1'
      : process.env.REACT_APP_BACKEND_URL || 'https://dev.steps.me/dev',
    embed_url: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_EMBED_URL || 'https://devembed.steps.me'
      : process.env.REACT_APP_EMBED_URL || 'https://devembed.steps.me',
  },
  mixpanel: {
    token: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_MIXPANEL_TOKEN || '72123733c2cb16af4962b13a8c0c1243'
      : process.env.REACT_APP_MIXPANEL_TOKEN || '72123733c2cb16af4962b13a8c0c1243',
  },
  branch: {
    key: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_BRANCH_KEY || 'key_live_lnS6lLZQ42dahHSK3DNv4gjpAubqgRM4'
        : process.env.REACT_APP_BRANCH_KEY || 'key_test_mgM9pR4R59bbjJIOYzK6AlbaqxmFmHXb',
  },
  version: {
    path: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_VERSION || '1.0.1'
        : process.env.REACT_APP_VERSION || '1.0.1',
  },
  site: process.env.REACT_APP_ENV === 'production' ? 'https://web.steps.me' : 'https://devweb.steps.me',
};
